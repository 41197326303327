<template>
  <div class="user">
    <!-- update user profile -->
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-card>
            <v-card-title>
              <span class="headline">Update Profile</span>
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-text-field
                  v-model="name"
                  label="Display Name"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  label="Email"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="test">Update</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import { updateProfile } from '@firebase/auth';
import { auth  } from "../fb";
export default {
    name: "user",
    data() {
        return {
        user: null,
        name: "",
        email: "",
        photoURL: "",
        emailVerified: "",
        uid: "",
        };
    },

    methods: {
        test(){
            updateProfile(auth.currentUser, {
                displayName: this.name
              }).then(() => {
                console.log('pass');
              }).catch((error) => {
                console.log(error);
              });
        }
    },
    mounted(){
        auth.onAuthStateChanged((user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              this.user = user;
              this.name = user.displayName;
              this.email = user.email;
              this.photoURL = user.photoURL;
              this.emailVerified = user.emailVerified;
              this.uid = user.uid;
              // ...
            } else {
              // User is signed out
              // ...
            }
          });
    }
};
</script>

<style>

</style>
